.rbc-calendar{
    background: white;
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
    font-weight: 600;
}

.rbc-toolbar{
    background: $primary;
    color: white;
    padding: 1rem;
    margin-bottom: 0;

    .rbc-btn-group{
        button {
            background: $secondary;
            border: none;
            color: white;
            padding: 0.5rem 1rem;
            transition: all .25s ease-in-out;

            &:hover{
                background: lighten($secondary, 10%);
            }

            &:active{
                color: white;
            }

            &.rbc-active{
                background: $success;
                color: $secondary;

                &:hover{
                    background: $success;
                }
            }
        }

        &:first-child{
            button:first-child {
                background: $success;
                color: $secondary;

                &:hover{
                    background: darken($success, 10%);
                }
                &:active{
                    color: $secondary;
                }
            }
        }
    }

    .rbc-toolbar-label {
        font: 600 20px "Rubik", sans-serif;
    }
}

.rbc-event {
    &.na {
        background-color: $danger;
    }

    &.available {
        background-color: $success;
    }

    &.invoiced {
        background-color: $success;
    }

    &.not-invoiced {
        background-color: $danger;
    }
}

//Month View
.rbc-month-header{
    .rbc-header{
        padding: 0.5rem 0;
        background: white;
    }
}

.rbc-day-bg{   
    background: #F9F9F9; 
    &.rbc-off-range-bg{
        background: #EFEFEF;
    }

    &.rbc-selected-cell {
        background: lighten($primary, 40%);
    }
}

//Day View
.rbc-time-header-cell, .rbc-time-slot, .rbc-time-header-gutter  {
    background: white;
}

.rbc-time-header-cell {

    & .rbc-today{
        background: $success;
        color: $secondary;
    }

    & .rbc-header span{
        font-family: "Rubik", sans-serif;
        font-weight: 600;
    }
}

.rbc-time-slot{
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 50px !important;
    & .rbc-label {
        font-family: "Rubik", sans-serif;
        font-weight: 500;
    }
}


//Event modal
.calendar-event-header{
    border-bottom: 1px solid #707070;
    padding: 1rem;
    background: #FFF;
    border-radius: 1rem 1rem 0 0;
    .calendar-event-icon-container{
        display:flex;
        gap: 1rem;
        align-items: flex-start;
        & > div {
            padding: 0.25rem;
            transition: transform .25s ease-in-out;
           
            &:hover{
                transform: scale(1.2);
            }
        }
    }

    .calendar-event-date-row{
        display:flex;
        gap: 0.5rem;
        align-items: center;
        
        .calendar-event-date{
            padding: 0.3rem;
            border-radius: 4px;
            background: darken(#FFF, 10%);
        }

        .calendar-event-all-day-badge{
            background: $success;
            color: $secondary;
            padding: 0.3rem;
            margin-left: 0.5rem;
            border-radius: 4px;
        }
    }

    .calendar-event-all-day-row{
        margin-top: 0.5rem;
    }

    .calendar-event-edit-actions{
        display:flex;
        justify-content: flex-end;
        padding-top: 1rem;
        gap: 1rem;

        .accept-changes{
            padding: 0.2rem;
            transition: all .25s ease-in-out;

            &:not(.invalid):hover{
                background: rgba(0, 0, 0, 0.05);
            }
        }

        .discard-changes{
            padding: 0.2rem;
            transition: all .25s ease-in-out;

            &:hover{
                background: rgba(0, 0, 0, 0.05);
            }
        }
    }
}

.calendar-event-body{
    padding: 1rem;
}

.edit-mode{
    background: #FFF;
}

.calendar-interview-event-registrant{
    margin-top: 1rem;
}