.rbt-menu.dropdown-menu{
    background: #FFF;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    a{ 
        text-decoration: none;
    }
}

.searchable-dropdown{
    &.invalid {
        
        border-color: $danger;            
        

        &.focused{
            box-shadow: 0 0 0 1px $danger;
            border-color: $danger;
            &:hover {
                border-color: $danger;            
            }
        }
    }

    &.valid {
        border-color: $success;
        
        
        &.focused{
            box-shadow: 0 0 0 1px $success;
            border-color: $success;
            &:hover {
                border-color: $success;            
            }
        }
    }
}