@import './public-colours.scss';
.public {
    background: $white;
    
    .header-offset {
        margin-top: 4rem;
    }

    .mobile-reversed {
        @media screen and (max-width: 700px) {
            flex-direction: column-reverse; 
        }        
    }

    h1 {
        font: 600 2.5rem 'Rubik', sans-serif;
        color: $jet-black;
        line-height: 2.5rem;
        margin-bottom: 2rem;
                        
        &.brand {
            color: $white;
            font: 600 2.5rem 'Playfair Display', sans-serif;
            margin-bottom: 0;

            &.alt {
                color: $jet-black;
                line-height: 2.5rem;
            }
        }

        &.eb {
            font-weight: 800;
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        
        &.header {
            color: #ffffff;
        }
    }

    

    h2 {
        &.alt {
            font: 600 1.5rem 'Lato', sans-serif;
        }
    }

    h6 { 
        font-family: 'Rubik', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: $accent-public;
        line-height: 1rem;     
        margin-bottom: 0.3rem;   

        &.brand {
            font-family: 'Playfair Display', serif;            
            font-weight: 500;
            font-size: 1.5rem;
            color: #FFFFFF;
        }
    }
    
    a {
        color: $accent-public;
    }

    p {
        font-family: 'Open Sans', sans-serif;
    }

    ul {
        list-style-image: url("../../../public/images/bullet.png");
        li {
            padding-bottom: 1rem;
            padding-left: 0.5rem;
        }
    }
    
    button.pgs {
        border-radius: 5px;
        padding: 1rem 2rem;
        border: none;

        &.primary {
            background: $accent-public;
            color: $white;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        &.secondary {
            background: $grey-public-1;
            color: $white;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }    

    .secondary-bg {
        background: $grey-public-1;
    }

    .tertiary-bg {
        background: $accent-public-light;
    }

    .dark-bg {
        background: $navy-public;
    }

    .drawer-interaction {
        transition: all .25s ease-in-out;
        &.open {
            transform: translateX(260px);

            .menu-icon {
                opacity: 0;              
            }
        }
    }  

    .section-image {
        img {
            width: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
        
        &.banner {
            height: 450px;
            overflow:hidden;
            border-radius: 5px;
            margin-bottom: 2rem;            
        }

        @media screen and (max-width: 700px) {
            img {
                margin-bottom: 2rem;
            }

            &.banner {
                max-height: 400px;
                height: auto;
                overflow:hidden;
                border-radius: 5px;  
                margin-bottom: 2rem;
                img {
                    margin-bottom: 0;
                }                        
            }
        }
    }

    .oversize-image-section {           
        margin-bottom: 3rem;
        min-height: 500px;
        div {            
            position:relative;
            align-items:center;
            height: 500px;

            &.text {
                margin-top: -5%;
            }
        }
        img {
            position: absolute;
            height: 110%;
            margin-top: calc((5% + 3rem) * -1);

            // @media screen and (max-width: 1020px) {
            //     margin-top: -17%;
            // }

            // @media screen and (max-width: 900px) {
            //     margin-top: -19%;
            // }

            // @media screen and (max-width: 800px) {
            //     margin-top: -21%;
            // }
        }

        @media screen and (max-width: 800px) {
            height: 600px;
            div {
                height: 600px;
            }
        }

        @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
            
            div {
                width: 100%;
                height: auto;

                &.text {
                    margin-top: 0;
                }
            }

            img {
                position:relative;
                width: 100%;
                max-height: 400px;
                margin-top: 0;                
            }
        }
    }

    .oversize-adjust{
        margin-top: -3rem;
        padding-top: 3rem;

        @media screen and (max-width: 700px) {
            margin-top: 0;
            padding-top: 0;
        }
    }
    .accordion {
        --bs-accordion-border-radius: 25px;
        --bs-accordion-inner-border-radius: 24px;
    }
    .accordion-header button{
        font: 600 1rem "Lato", sans-serif;
        border-color: white;
        box-shadow: none;
        &[aria-expanded="true"]{
            background: $navy-public;
            color: $white;

            &:after {
                background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>");
            }
        }   
    }

    .accordion-collapse {
        background: $navy-public-dark;
        color: #FFF;
    }

    .footer {
        background: $navy-public;
        position:relative;
        padding-bottom: 3rem;
        .extra-footer {
            width:100%;
            height: 3rem;
            background: rgba(0, 0, 0, 0.35);
            position: absolute;
            bottom: 0;
        }
    }


}

.MuiPaper-root.MuiDrawer-paper {
    background-color: $navy-public;
    overflow: visible;

    .drawer-close-icon {
        position: absolute;
        width: 48px;
        height: 48px;
        background: $accent-public;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 8px; 
        left: 278px;
        transition: all .1s ease-in-out;
    
        &:hover {
            background: $accent-public-dark; 
        }
    
        svg { 
            width: 28px;
            height: 28px;
            path {
                fill: #FFFFFF;
            }
        }

        &.hidden {
            transform: scale(0);
        }
    }   
        
}

.MuiBox-root .MuiList-root {
    margin: 1rem;
    background: rgba(181, 183, 188, 0.8);
    border-radius: 1rem;
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItem-root{ 
        &:first-child{
            border-radius: 1rem 1rem 0 0;
        }

        &:last-child {
            border-radius:  0 0 1rem 1rem;
        }
        &.active {
            background: $accent-public;            
            .MuiListItemButton-root .MuiListItemText-root span{
                color: $white;
            }
        }
        .MuiListItemButton-root{
            .MuiListItemText-root{
                span {
                    font: 600 1.1rem 'Open Sans', sans-serif;
                    text-align: left;
                    color: $navy-public;
                }
            }
        }
    }    
}

.MuiAppBar-root .MuiToolbar-root {
    justify-content: space-between;
     .MuiButton-text {
        position: relative;      
        font: 500 1.1rem 'Open Sans', sans-serif;
        text-transform: none;

        &.active {
            .active-accent {
                position:absolute;
                bottom:4px;
                background: $accent-public;
                height: 4px;
                width:100%;   
            }

        }

        &:hover{
            background-color: $accent-public-light;
        }
    }
}

.drawer {
    margin: 2rem 1rem;        
}

.desk-nav {
    button {
        height: 48px;
        text-wrap: nowrap;
    }
}

button.round-btn {
    border-radius: 25px;
    padding: 1rem 2rem;
    border: none;
    width: 100%;
    font: 600 1.1rem 'Open Sans', sans-serif;

    &.primary {
        background: $accent-public;
        color: $white;        
    }

    &.secondary {
        background: $grey-public-2;
        color: $white;        
    }
}

