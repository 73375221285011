html{
    font-size: 16px;
    overflow-x: hidden;    
}

body{
    margin: 0;
    padding: 0;
    background: #F8F8F8;
    color: #0b132b;
    overflow-x: hidden;  
    width:100vw;  
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Rubik', sans-serif;
}
h1{ 
    font-size: 2.25rem;
}

h2{
    font-size: 1.5rem;
}

h3{
    font-size: 1.2rem;
}

//Fix for skill display on new-profile form
.skill-container .col-md {
    flex: none;
}


//Scale for delete btn in role search
.deleteBtn:hover {
    transform: scale(1.2)!important;
}

$primary: #406e4c;

// Home Page

.carousel-indicators {
    margin-bottom: -2rem;

    & [data-bs-target] {
        background-color: $secondary;
    }
}

//Nav Rail menu icon positioning
.nav-items {     
    .menu-icon {
        left: 24px;
    }
}

//Type ahead dropdowns
.rbt-menu.dropdown-menu.show {
    z-index: 10;
}

//MultiSelect Accordion
.accordion-item.disabled{
    background: #e9ecef;

    & > .accordion-header > .accordion-button {
        background: #e9ecef;
    }
}

//Image nav
.image-chevron {
    position: absolute;
    top: 40%;
    background: rgba(255,255,255,0.25);
    backdrop-filter: blur(15px);
}

@media screen and (max-width: 1199px) {
    .rbc-header:nth-of-type(2) {
        max-width:130px!important;
    }
    .rbc-agenda-table tr{
        &:nth-child(2n+1) {
            background: #eeeeee;
        }
        .rbc-agenda-date-cell {
            background: #ffffff;
        }
    }

    .rbc-agenda-time-cell {
        
        span {        
            max-width:100px;
            width:130px;        
            display: block;
            white-space: wrap;
        }
    }
}