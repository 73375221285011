// Required
@import "./theme/bootstrap/scss/functions";

$primary: #0fa5ff;
$primaryLight: #B0E1FF;
$secondary: #0b132b;
$success: #43D9B2;
$danger: #f06969;

// Required
@import "./theme/bootstrap/scss/variables";
@import "./theme/bootstrap/scss/maps";
@import "./theme/bootstrap/scss/mixins";
@import "./theme/bootstrap/scss/root";

// Optional Bootstrap components here
@import "./theme/bootstrap/scss/reboot";
@import "./theme/bootstrap/scss/type";
@import "./theme/bootstrap/scss/forms";
@import "./theme/bootstrap/scss/buttons";
@import "./theme/bootstrap/scss/utilities";
@import "./theme/bootstrap/scss/helpers";
@import "./theme/bootstrap/scss/grid";
@import "./theme/bootstrap/scss/bootstrap-utilities.scss";
@import "./theme/bootstrap/scss/alert";
@import "./theme/bootstrap/scss/modal";
@import "./theme/bootstrap/scss/close";
@import "./theme/bootstrap/scss/card";
@import "./theme/bootstrap/scss/tables";
@import "./theme/bootstrap/scss/pagination";
@import "./theme/bootstrap/scss/navbar";
@import "./theme/bootstrap/scss/nav";
@import "./theme/bootstrap/scss/transitions";
@import "./theme/bootstrap/scss/carousel";
@import "./theme/bootstrap/scss/accordion";
@import "./theme/bootstrap/scss/badge";


@import 'react-big-calendar/lib/sass/styles';
@import './theme/calendar/calendar';
@import './theme/inputs/typeahead';
@import './theme/custom';
@import './theme/website/general.scss';